import * as React from "react"

export default function CoreValues() {
    return (
        <div className="container pb-5">
            <h1 className="pb-2 fw-bold">CORE VALUES</h1>
            <hr />
            <div className="row align-items-center justify-content-evenly">
                <div className="col-lg-4 py-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="d-block mx-auto w-50 p-3" viewBox="0 -8 480.00007 480">
                        <path fillRule="evenodd" clipRule="evenodd" d="m64 424.929688v31.046874c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-32c0-1.976562 0-5.648437-62.28125-157.25-1.140625-2.832031-1.722656-5.863281-1.71875-8.917968v-201.832032c0-4.417968 3.582031-8 8-8h16c4.417969 0 8 3.582032 8 8v105.472657c-9.554688 3.378906-15.957031 12.394531-16 22.527343-.003906.539063.046875 1.074219.160156 1.601563l16 80c.167969.839844.472656 1.652344.894532 2.398437l32 56c1.382812 2.554688 4.039062 4.160157 6.941406 4.195313s5.597656-1.503906 7.039062-4.023437c1.445313-2.519532 1.410156-5.621094-.089844-8.109376l-31.351562-54.902343-15.59375-77.816407c.34375-4.160156 3.824219-7.359374 8-7.34375h15.648438c3.828124 2.894532 6.886718 6.683594 8.902343 11.039063l23.785157 79.296875c.445312 1.484375 1.3125 2.808594 2.496093 3.808594 42.007813 35.511718 101.167969 94.351562 101.167969 113.855468v72c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-72c0-32.960937-83.671875-106.398437-105.015625-124.570312l-23.320313-77.726562c-.132812-.4375-.304687-.867188-.503906-1.28125-2.359375-4.789063-11.160156-20.421876-23.160156-20.421876h-8v-104c0-13.253906-10.746094-24-24-24h-16c-13.253906 0-24 10.746094-24 24v201.832032c-.0078125 5.128906.972656 10.210937 2.886719 14.96875 23.121093 56.230468 57.914062 141.855468 61.113281 152.152344zm0 0" />
                        <path d="m256 383.976562v72c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-72c0-19.503906 59.199219-78.34375 101.167969-113.894531 1.183593-1.003906 2.050781-2.324219 2.496093-3.808593l23.785157-79.296876c2.019531-4.242187 4.9375-7.996093 8.550781-11h16c4.175781-.015624 7.65625 3.183594 8 7.34375l-15.566406 77.816407-31.378906 54.839843c-1.5 2.484376-1.535157 5.589844-.089844 8.109376 1.441406 2.519531 4.136718 4.058593 7.039062 4.023437 2.902344-.039063 5.558594-1.644531 6.941406-4.195313l32-56c.421876-.75.726563-1.558593.894532-2.402343l16-80c.105468-.503907.160156-1.019531.160156-1.535157-.042969-10.132812-6.445312-19.148437-16-22.527343v-105.472657c0-4.417968 3.582031-8 8-8h16c4.417969 0 8 3.582032 8 8v201.832032c.007812 3.03125-.566406 6.03125-1.6875 8.847656-62.3125 151.671875-62.3125 155.34375-62.3125 157.320312v32c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-31.046874c3.199219-10.296876 37.992188-95.921876 61.144531-152.265626 1.890625-4.722656 2.859375-9.765624 2.855469-14.855468v-201.832032c0-13.253906-10.746094-24-24-24h-16c-13.253906 0-24 10.746094-24 24v104h-8c-12 0-20.761719 15.632813-23.199219 20.421876-.203125.414062-.371093.84375-.503906 1.28125l-23.320313 77.726562c-21.304687 18.171875-104.976562 91.609375-104.976562 124.570312zm0 0" />
                        <path d="m240 207.976562c35.574219 0 120-108.800781 120-144-.011719-29.179687-19.757812-54.65625-48.011719-61.945312-28.253906-7.292969-57.863281 5.453125-71.988281 30.984375-14.125-25.53125-43.734375-38.277344-71.988281-30.984375-28.253907 7.289062-48 32.765625-48.011719 61.945312 0 35.199219 84.425781 144 120 144zm-56-192c26.5.027344 47.972656 21.5 48 48v6.527344c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-6.527344c0-26.511718 21.492188-48 48-48s48 21.488282 48 48c0 29.902344-79.695312 128-104 128s-104-98.097656-104-128c.027344-26.5 21.5-47.972656 48-48zm0 0" />
                    </svg>
                    <h2 className="text-center">Compassion</h2>
                </div>
                <div className="col-lg-4 py-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="d-block mx-auto w-50 p-3" viewBox="-16 0 480 480.0644">
                        <path fillRule="evenodd" clipRule="evenodd" d="m192.019531 256.0625c29.207031 0 96-81.007812 96-108.796875-.257812-28.484375-23.515625-51.386719-52-51.203125-17.789062-.035156-34.371093 8.984375-44 23.9375-9.632812-14.953125-26.214843-23.972656-44-23.9375-28.484375-.183594-51.746093 22.71875-52 51.203125 0 27.789063 66.789063 108.796875 96 108.796875zm-44-144c19.648438-.195312 35.753907 15.550781 36 35.203125 0 4.417969 3.582031 8 8 8s8-3.582031 8-8c0-19.882813 16.117188-36 36-36 19.878907 0 36 16.117187 36 36 0 20.796875-62.058593 92.796875-80 92.796875-17.945312 0-80-72-80-92.796875.246094-19.652344 16.347657-35.398437 36-35.203125zm0 0" /><path d="m64.019531 346.558594v125.503906c0 4.421875 3.582031 8 8 8h192c4.417969 0 8-3.578125 8-8v-88h104c4.417969 0 8-3.578125 8-8v-104h56c2.722657 0 5.261719-1.386719 6.730469-3.683594 1.472656-2.296875 1.671875-5.183594.53125-7.660156l-73.75-160c-.105469-.230469-.21875-.445312-.335938-.664062-39.28125-68.109376-114.835937-106.875-193.074218-99.066407-78.238282 7.8125-144.636719 60.75-169.675782 135.285157-25.042968 74.53125-4.078124 156.820312 53.574219 210.285156zm136-330.496094c65.558594.15625 126.125 35.050781 159.140625 91.691406l68.363282 148.308594h-51.503907c-4.417969 0-8 3.582031-8 8v104h-104c-4.417969 0-8 3.582031-8 8v88h-176v-121.039062c0-2.273438-.96875-4.441407-2.664062-5.960938-56.867188-50.832031-76.453125-131.496094-49.226563-202.746094 27.226563-71.25 95.613282-118.296875 171.890625-118.253906zm0 0" />
                    </svg>
                    <h2 className="text-center">Understanding</h2>
                </div>
                <div className="col-lg-4 py-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="d-block mx-auto w-50 p-3" viewBox="0 0 512 512">
                        <g>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M509.042,355.862l-24.409-24.21c-3.923-3.891-10.255-3.864-14.145,0.058c-3.891,3.922-3.864,10.255,0.058,14.145
                			l24.408,24.21c1.95,1.935,4.497,2.901,7.043,2.901c2.573,0,5.146-0.987,7.101-2.959
                			C512.99,366.085,512.964,359.752,509.042,355.862z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M459.762,306.881c-3.906-3.906-10.237-3.906-14.145,0l-0.017,0.017c-3.906,3.906-3.897,10.231,0.009,14.137
                			c1.95,1.951,4.509,2.926,7.067,2.926c2.564,0,5.13-0.979,7.085-2.934C463.667,317.121,463.667,310.788,459.762,306.881z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M374.493,353l97.028-97.027c14.695-14.696,14.695-38.608-0.001-53.304c-5.182-5.182-11.511-8.528-18.177-10.055
                			c2.286-4.914,3.496-10.307,3.496-15.888c0-10.068-3.921-19.533-11.039-26.651c-5.583-5.584-12.612-9.19-20.241-10.489
                			c4.295-13.086,1.259-28.073-9.128-38.459c-5.711-5.71-12.814-9.19-20.227-10.462c1.242-3.769,1.897-7.749,1.897-11.833
                			c0-10.068-3.921-19.533-11.039-26.651c-7.119-7.118-16.584-11.039-26.651-11.039c-6.393,0-12.54,1.586-18.001,4.564l-0.965-1.037
                			c-12.542-13.478-30.295-21.207-48.704-21.207h-78.54c-5.523,0-10.002,4.479-10.002,10.002v13.116
                			c0,5.249,0.888,10.359,2.586,15.164c-4.524-1.879-9.419-2.866-14.465-2.866c-10.068,0-19.533,3.921-26.652,11.039
                			c-10.392,10.392-13.425,25.39-9.12,38.48c-7.419,1.269-14.532,4.751-20.248,10.466c-7.118,7.119-11.039,16.584-11.039,26.651
                			c0,4.076,0.653,8.05,1.89,11.811c-7.62,1.303-14.642,4.907-20.22,10.486c-11.477,11.477-13.99,28.577-7.539,42.516
                			c-6.831,1.561-13.105,5.001-18.183,10.078c-14.695,14.695-14.695,38.607,0,53.303l19.954,19.953L2.929,391.893
                			c-3.906,3.906-3.906,10.239,0,14.146c1.953,1.952,4.513,2.93,7.072,2.93s5.119-0.977,7.072-2.93l99.182-99.182
                			c5.615,3.204,11.987,4.918,18.628,4.918c10.067,0,19.532-3.921,26.651-11.04c7.734-7.733,11.392-18.02,10.985-28.172
                			c0.507,0.021,1.014,0.036,1.521,0.036c9.65-0.001,19.305-3.675,26.651-11.021c7.118-7.119,11.039-16.584,11.039-26.651
                			c0-0.509-0.017-1.015-0.037-1.521c0.501,0.02,1.002,0.036,1.504,0.036c9.651,0,19.304-3.674,26.651-11.021
                			c7.118-7.119,11.039-16.584,11.039-26.651c0-0.509-0.017-1.015-0.037-1.521c0.501,0.02,1.002,0.036,1.504,0.036
                			c9.652,0,19.304-3.674,26.651-11.021c13.808-13.808,14.639-35.751,2.5-50.542l66.394-66.394c3.341-3.342,7.781-5.181,12.506-5.181
                			s9.166,1.839,12.507,5.181c3.341,3.341,5.18,7.781,5.18,12.506c0,4.724-1.839,9.166-5.181,12.506l-9.789,9.789l-35.518,35.518
                			c-3.906,3.906-3.906,10.239,0,14.146c1.953,1.952,4.513,2.93,7.072,2.93s5.119-0.977,7.072-2.93l35.518-35.518
                			c6.895-6.896,18.116-6.897,25.013-0.001c6.896,6.897,6.896,18.117,0,25.014l-9.79,9.79l-25.728,25.728
                			c-3.906,3.906-3.906,10.239,0,14.146c1.953,1.952,4.513,2.93,7.072,2.93s5.119-0.977,7.072-2.93l25.728-25.728
                			c3.341-3.342,7.782-5.181,12.506-5.181c4.725,0,9.166,1.839,12.507,5.181c3.341,3.341,5.18,7.781,5.18,12.506
                			c0,4.724-1.839,9.166-5.181,12.506l-13.437,13.437l-12.291,12.291c-3.906,3.906-3.906,10.239,0,14.146
                			c1.953,1.952,4.513,2.93,7.072,2.93s5.119-0.977,7.072-2.93l12.3-12.3c6.897-6.885,18.111-6.884,25.004,0.009
                			c6.897,6.896,6.897,18.117,0,25.014l-97.028,97.027c-24.364,24.365-56.745,38.47-91.178,39.72
                			c-2.531,0.092-4.935,1.141-6.723,2.937l-89.632,89.964c-3.899,3.913-3.888,10.246,0.026,14.145
                			c1.951,1.944,4.505,2.917,7.059,2.917c2.565,0,5.132-0.981,7.085-2.943l65.927-66.17c16.746,10.743,36.44,15.851,56.465,14.527
                			l51.598,51.599c1.953,1.952,4.513,2.93,7.072,2.93c2.56,0,5.119-0.977,7.072-2.93c3.906-3.906,3.906-10.239,0-14.146
                			l-54.86-54.866c-2.077-2.076-4.963-3.137-7.89-2.897l-3.171,0.26c-14.637,1.2-29.105-2.006-41.737-9.085l6.428-6.451
                			C311.894,396.026,347.486,380.008,374.493,353z M147.39,286.591c-3.341,3.342-7.783,5.181-12.506,5.181
                			c-4.725,0-9.166-1.839-12.506-5.181l-27.026-27.025c-6.896-6.896-6.896-18.117,0-25.014c3.341-3.342,7.783-5.181,12.507-5.181
                			c4.724,0,9.166,1.839,12.506,5.181l27.026,27.025C154.286,268.473,154.286,279.694,147.39,286.591z M186.549,247.432
                			c-6.897,6.896-18.12,6.896-25.014,0l-27.026-27.026l-13.438-13.438c-6.896-6.896-6.896-18.117,0-25.013
                			c3.341-3.342,7.783-5.181,12.506-5.181c4.725,0,9.166,1.839,12.506,5.181l40.463,40.463c0,0,0,0,0.001,0
                			c3.342,3.342,5.181,7.783,5.181,12.507S189.889,244.091,186.549,247.432z M225.706,208.274c-6.897,6.895-18.119,6.896-25.014,0
                			l-50.253-50.253c-3.341-3.341-5.18-7.781-5.18-12.506c0-4.724,1.839-9.166,5.181-12.506c3.448-3.448,7.978-5.172,12.506-5.172
                			c4.526,0,9.051,1.722,12.498,5.164l22.904,22.904l27.356,27.356h0.001c3.34,3.341,5.179,7.781,5.179,12.506
                			C230.886,200.492,229.047,204.934,225.706,208.274z M264.864,169.116c-6.896,6.896-18.117,6.896-25.013,0l-50.253-50.253
                			c-0.005-0.005-0.011-0.01-0.017-0.016l-9.773-9.773c-6.896-6.897-6.896-18.117,0-25.014c3.448-3.449,7.978-5.173,12.507-5.173
                			c4.529,0,9.059,1.724,12.506,5.173l60.041,60.041c3.342,3.341,5.181,7.782,5.181,12.506
                			C270.044,161.333,268.206,165.776,264.864,169.116z M267.494,118.444l-27.472-27.472c3.115,0.665,6.318,1.018,9.578,1.017
                			c0.073,0,0.147,0,0.221,0l27.448-0.129c5.523-0.026,9.981-4.525,9.955-10.049c-0.026-5.508-4.499-9.955-10.001-9.955
                			c-0.016,0-0.032,0-0.048,0l-27.448,0.129c-0.042,0-0.082,0-0.124,0c-6.763,0-13.124-2.625-17.92-7.398
                			c-4.826-4.804-7.483-11.198-7.483-18.008v-3.114h68.538c12.875,0,25.29,5.405,34.062,14.831l0.407,0.437L267.494,118.444z" />
                            </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg>
                    <h2 className="text-center">Resilience</h2>
                </div>
            </div>
        </div>

    )
}
